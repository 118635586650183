import './VGrid.scss';
import Vue from 'vue';

const name = 'grid-page-column';

export default Vue.extend({
    name,

    functional: true,

    props: {
        id: String,
        tag: {
            type: String,
            default: 'div',
        },
    },

    render(h, { props, data, children }) {
        data.staticClass = `${name} ${data.staticClass || ''}`.trim();

        const { attrs } = data;
        if (attrs) {
            data.attrs = {};

            for (const key in attrs) {
                if (key === 'slot') {
                    continue;
                }

                const value = attrs[key];

                if (key.startsWith('data-')) {
                    data.attrs[key] = value;
                    continue;
                }

                data.staticClass += ` ${key}`;
            }
        }

        if (props.id) {
            data.domProps = data.domProps || {};
            data.domProps.id = props.id;
        }

        return h(props.tag, data, [
            h('div', { staticClass: 'grid-page-column__context' }, children),
            h('div', { staticClass: 'grid-page-column__separator' }),
        ]);
    },
});
