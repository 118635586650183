import Vue from 'vue';

export default function Grid(name: string) {
    return Vue.extend({
        name: `v-${name}`,

        functional: true,

        props: {
            id: {
                type: String,
                default: '',
            },
            tag: {
                type: String,
                default: 'div',
            },
        },

        render(h, { props, data, children }) {
            data.staticClass = `${name} ${data.staticClass || ''}`.trim();

            const { attrs } = data;

            if (attrs) {
                data.attrs = {};

                for (const key in attrs) {
                    if (key === 'slot') {
                        continue;
                    }

                    const value = attrs[key];

                    if (key.startsWith('data-') || key === 'role') {
                        data.attrs[key] = value;
                        continue;
                    }

                    data.staticClass += ` ${key}`;
                }
            }

            if (props.id) {
                data.domProps = data.domProps || {};
                data.domProps.id = props.id;
            }

            return h(props.tag, data, children);
        },
    });
}
